<template>
  <div class="shop_containerr">
    <div class="shop_container">
         <!-- 图片 -->
    <div class="shop_img">
      <img src="../../image/xiaDan.png" alt="" srcset="">
    </div>
    <!-- 详细说明 -->
    <div class="shop_details">
       <div class="shop_payNamey">
         ￥3000金袋票
       </div>
       <div class="shop_body">
         <div class="shop_body_left">原价</div>
         <div class="shop_body_right">￥3000.00</div>
       </div>
       <div class="shop_body">
         <div class="shop_body_left">现价</div>
         <div class="shop_body_right textDecoration">
           <span class="shop_body_Icon">￥</span>
           <div class="shop_body_Number">
             2950.00
           </div>
           <div class="saveMoney">
             (节省50元)
           </div>
          </div>
       </div>
       <div class="shop_body">
         <div class="shop_body_left">仓票说明</div>
         <div class="shop_body_right textDecorationDeatile">06.01后激活到结算账户，长期有效，可抵扣租金、服务费、运费等。</div>
       </div>
       <div class="shop_body shop_body_box">
         <div class="shop_body_left shop_body_left_box">
           <img src="../../image/iconImg.png" alt="" srcset="">
           <span>剩余： 188件</span>
         </div>
       </div>
       <!-- 立即抢购 -->
       <div class="shop_body shop_body_boxHeight">
          <div class="submint" @click="goShopClick">
            立即抢购
          </div>
       </div>
      <div class="shop_body shop_body_list">
         <div class="shop_body_detali">
           仓票说明
         </div>
         <div class="shop_body_bottom shop_body_list">
           <div class="shop_body_bottom_item">
             1.购买和使用仓票是客户获得优惠的唯一途径。
           </div>
           <div class="shop_body_bottom_item">
             2.仓票只有代叔科技签约客户才可购买使用，其他人购买无效
           </div>
           <div class="shop_body_bottom_item">
             3.仓票归属系统页面标注的月份激活，激活后才可抵扣当月及以后的费用抵扣
           </div>
           <div class="shop_body_bottom_item">
             4.当月未用完的仓票余额自动滚存 至下月使用，长期有效
           </div>
           <div class="shop_body_bottom_item">
             5.已到使用的月份仓票不可转赠，不可提现，如需退票退款，则扣除支付金额的20%用于发票费用及手续费
           </div>
           <div class="shop_body_bottom_item">
             6.未到使用月份的仓票可以全额退款
           </div>
           <div class="shop_body_bottom_item">
             7.最终解释归代叔科技所有
           </div>
         </div>
      </div>
    </div>
    </div>
    <div class="shop_success" v-if="Immediately">
      <div class="shop_body_left">
        <img src="../../image/paySuccess.png" alt="" srcset="">
        <img class="payCloneXX" src="../../image/payCloneXX.png" @click="cloneX" alt="" srcset="">
        <div class="shop_body_text">
          <div class="shop_body_price">
            ￥2950.00
          </div>
          <div class="shop_body_payWay_box">
            <div class="shop_body_payWay">
            <img class="shop_body_payWay_img" src="../../image/wxpay.png" alt="" srcset="">
            <div class="shop_body_payWay_text">
              微信支付
            </div>
          </div>
          <div class="shop_body_payWay">
            <img class="shop_body_payWay_img" src="../../image/xshpay.png" alt="" srcset="">
            <div class="shop_body_payWay_text">
              线下支付
            </div>
          </div>
          </div>
        </div>
    
      </div>
      <div class="shop_body_right"></div>
    </div>
    <div class="shop_mode"  v-if="Immediately">
      
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // 立即购买
      Immediately: false
    };
  },
  methods: {
    goShopClick() {
      this.Immediately=!this.Immediately
    },
    cloneX(){
      this.Immediately=!this.Immediately

    }
  }
};
</script>
<style lang="less" scoped>
.shop_container {
  padding: 33px 0 0 20px;
  display: flex;
  .shop_img {
    width: 300px;
    height: 208px;
    margin-right: 30px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .shop_details {
    .shop_payNamey {
      font-size: 30px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #ff3e00;
      height: 29px;
      line-height: 29px;
    }
    .shop_body {
      display: flex;
      height: 14px;
      line-height: 14px;
      margin-top: 25px;
      margin-left: 5px;
      .shop_body_left {
        height: 14px;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #666666;
        opacity: 0.7;
        width: 58px;
        margin-right: 20px;
      }
      .shop_body_left_box {
        width: 200px;
        display: flex;
        align-items: center;
        span {
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #ff6036;
          margin-left: 12px;
        }
      }
      .shop_body_right {
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        text-decoration: line-through;
        color: #666666;
        .shop_body_Icon {
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #ff3e00;
        }
        .shop_body_Number {
          height: 19px;
          line-height: 19px;
          font-size: 25px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #ff4804;
        }
        .saveMoney {
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #ff4804;
          height: 19px;
          margin-left: 6px;
        }
      }
      .textDecoration {
        text-decoration: unset;
        display: flex;
        align-items: flex-end;
      }
      .textDecorationDeatile {
        text-decoration: unset;
        width: 366px;
        line-height: 23px;
        margin-top: -5px;
      }
      .textDecorationDeat {
        text-decoration: unset;
      }
      .submint {
        width: 171px;
        height: 47px;
        background: #fd692a;
        border-radius: 24px;
        font-size: 22px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #fefefe;
        text-align: center;
        line-height: 47px;
        cursor: pointer;
      }
      .shop_body_detali {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #666666;
        margin-bottom: 20px;
      }
      .shop_body_bottom {
        .shop_body_bottom_item {
          width: 440px;
          font-size: 12px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #999999;
          margin-bottom: 9px;
          line-height: 20px;
        }
      }
    }
    .shop_body_boxHeight {
      height: 47px;
    }
    .shop_body_box {
      margin-top: 40px;
    }
    .shop_body_list {
      display: unset;
    }
  }
}
.shop_success {
  position: fixed;
  flex: 50%;
  top: 50%;
  transform: translate(25%, -50%);
  z-index: 99;
  .shop_body_left {
    width: 565px;
    height: 621px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    .payCloneXX {
      position: absolute;
      width: 36px;
      height: 36px;
      top: 49px;
      left: 51px;
      cursor: pointer;
    }
    img {
      position: absolute;
      width: 100%;
      height: 100%;
    }
    .shop_body_text {
      z-index: 100;
      .shop_body_price {
        font-size: 50px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #ff4e00;
      }
    }
    .shop_body_payWay_box {
      display: flex;
      justify-content: space-between;
      margin-top: 70px;
    }
    .shop_body_payWay {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .shop_body_payWay_img {
        width: 69px;
        height: 69px;
        position: relative;
      }
      .shop_body_payWay_text {
        font-size: 20px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #333333;
        margin-top: 20px;
      }
    }
  }
}
.shop_mode {
  position: fixed;
  width: 100%;
  height: 1469px;
  background: #000000;
  opacity: 0.6;
  left: 0;
  top: 0;
  z-index: 9;
}
</style>
